/* Coolors Exported Palette - coolors.co/0f4c81-fe4a49-fed766-009fb7-f8f4e3 */
/* New Coolors Exported Palette - coolors.co/0b365b-fe4a49-fed766-009fb7-f8f4e3 */

const darkBlue = 'rgba(11, 54, 91, 1)'
const tartOrange = 'rgba(254, 74, 73, 1)'
const yellow = 'rgba(254, 215, 102, 1)'
const bondiBlue = 'rgba(0, 159, 183, 1)'
const linen = 'rgba(248, 244, 227, 1)'

// Purple

const purple1 = '#210233'
const purple2 = '#360452'
const purple3 = '#4F0A77'
const purple4 = '#7F24B4'
const purple5 = '#B365E1'
const purple6 = '#D296F5'
const purple7 = '#E1B4FF'

// Orange

const orange1 = '#271906'
const orange2 = '#653D05'
const orange3 = '#A46102'
const orange4 = '#E08300'
const orange5 = '#F99D1C'
const orange6 = '#F7B558'
const orange7 = '#EDCB9C'

// PurpleGreys

const purpleGrey1 = '#262228'
const purpleGrey2 = '#48404C'
const purpleGrey3 = '#695F70'
const purpleGrey4 = '#8B7D93'
const purpleGrey5 = '#AC9CB7'
const purpleGrey6 = '#CEBADB'
const purpleGrey7 = '#F0D9FF'

// OrangeGrey

const orangeGrey1 = '#1F1D19'
const orangeGrey2 = '#453F37'
const orangeGrey3 = '#6A6155'
const orangeGrey4 = '#8F8372'
const orangeGrey5 = '#B4A590'
const orangeGrey6 = '#D9C7AE'
const orangeGrey7 = '#FEE9CC'

// Sea Green

const seaGreen1 = '#1E301C'
const seaGreen2 = '#3F623A'
const seaGreen3 = '#63945C'
const seaGreen4 = '#7FB685' //base
const seaGreen5 = '#9FC89A'
const seaGreen6 = '#BCD7B8'
const seaGreen7 = '#D7E5D6'

//Cerulean Blue

const ceruleanBlue1 = '#081C2A'
const ceruleanBlue2 = '#1A405D'
const ceruleanBlue3 = '#3B6B95'
const ceruleanBlue4 = '#5F8FB9'
const ceruleanBlue5 = '#93BEDF' //base
const ceruleanBlue6 = '#BBD9ED'
const ceruleanBlue7 = '#E6EFF6'

//China Pink

const chinaPink1 = '#330619'
const chinaPink2 = '#76133D'
const chinaPink3 = '#A9245D'
const chinaPink4 = '#CB3B78'
const chinaPink5 = '#E56399' //base
const chinaPink6 = '#F195B9'
const chinaPink7 = '#F6D0DC'

export const colours = {
  text: {
    light: purple2, // white
    dark: orange6, // light orange
  },
  textAlt: {
    light: orangeGrey3, // white
    dark: purpleGrey6, // light orange
  },
  textOnPrimaryDark: {
    light: orangeGrey6, // white
    dark: purpleGrey6, // light orange
  },
  headerText: {
    light: purple3, // mid-dark purple
    dark: orange4, // mid orange
  },
  buttonAlt: {
    light: purple3, // mid-dark purple
    dark: orange4, // mid orange
  },
  buttonAltDarker: {
    light: purple2, // mid-dark purple
    dark: orange3, // mid orange
  },
  textOnButtonAltDark: {
    light: purpleGrey6, // white
    dark: orangeGrey6, // light orange
  },
  background: {
    light: orangeGrey7, // lightest muted orange
    dark: purpleGrey1, // darkest muted purple
  },
  primary: {
    light: orange3, // Pinkish-red
    dark: purple5, // mid-light purple
  },
  primaryDarker: {
    light: orange2, // Pinkish-red
    dark: purple4, // mid-light purple
  },
  secondary: {
    light: purple4, // Purplish-blue
    dark: orange7, // lightest orange
  },
}

export const moon = {
  background: colours.background.dark,
  border: purpleGrey3,
  color: colours.primary.dark,
}

export const sun = {
  background: colours.background.light,
  border: orangeGrey5,
  color: colours.primary.light,
}

export const COLOR_MODE_KEY = 'color-mode'
