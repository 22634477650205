import React from 'react';

import { ThemeProvider } from '../context/ThemeContext';
import GlobalStyles from './GlobalStyles';

const App = ({ children }) => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
}

export default App;