import React from "react"
import { Global, css } from "@emotion/core"
import { colours } from "../styles/colours"

const generateColours = (colours, mode) =>
  Object.keys(colours).reduce(
    (accumulator, currentColour) =>
      `${accumulator}--${currentColour}:${colours[currentColour][mode]};`,
    ""
  )

const lightColours = generateColours(colours, "light")
const darkColours = generateColours(colours, "dark")

const GlobalStyles = () => (
  <Global
    styles={css`
      body {
        min-height: 100vh;
        background-color: var(--background);
        transition: color 0.2s ease-out, background 0.2s ease-out;
        ${lightColours}
      }
      body.dark {
        ${darkColours}
      }
      a {
        color: var(--secondary);
      }
      a:hover {
        text-decoration-color: var(--primary);
      }
      a:active {
        color: var(--primary);
      }
    `}
  />
)

export default GlobalStyles
